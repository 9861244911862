'use client';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import { useFriendlyViewPort } from '../../hooks/useFriendlyViewPort';
import { useViewportSizeTracking } from '../../hooks/useViewportSizeTracking';
import { useVal } from '../../val/val.client';
import homePageVal from '../../content/pages/forsida.val';
import Link from 'next/link';
import headerVal from '../../content/header.val';

const Index = () => {
  const { title } = useVal(homePageVal);
  const { navigationItems } = useVal(headerVal);

  useFriendlyViewPort();
  useViewportSizeTracking();

  const textStyle =
    'font-respira text-respira-lg tablet:text-respira-xl laptop:min-h-[0vh] leading-blank-tight tracking-blank-tight transition-50ms break-words hyphens-manual text-pretty';

  return (
    <>
      <div id="frontPage">
        <Header />
        <div className="laptop:h-screen pb-96">
          <div className="relative h-screen">
            <div className="flex flex-col laptop:justify-between laptop:items-center h-full px-16 laptop:px-24 laptop:flex-row gap-32 pt-header-height laptop:pt-0">
              <h1 className={`${textStyle} `}>{title}</h1>
              <div className="flex flex-col items-end gap-16 laptop:gap-24 laptop:top-header-height laptop:sticky shrink-0">
                {navigationItems.map((link) => (
                  <Link
                    href={link.url}
                    key={link.url}
                    className="text-2xl tablet:text-xl no-underline"
                  >
                    {link.title}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Index;
