import { s, c } from '../../val.config';
import { pageMetadataSchema } from '../schema/pageMetadata.val';

export const schema = s.object({
  title: s.string(),
  pageMetadata: pageMetadataSchema,
});

export default c.define('/content/pages/forsida.val.ts', schema, {
  title: 'Spesialister på digital produkt\u00ADutvikling',
  pageMetadata: {
    title: 'Spesialister på digital produktutvikling',
    description:
      'Spesialister på digital produktutvikling og et litt annerledes konsulentselskap i Oslo. Designere og teknologer som lager bra greier for kundene våre og oss selv.',
    url: '/',
    image: null,
  },
});
