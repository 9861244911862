import { s, t } from '../../val.config';

export const pageMetadataSchema = s.object({
  title: s.string(),
  description: s.string(),
  url: s.string(),
  image: s.image().nullable(),
});

export type PageMetadataSchema = t.inferSchema<typeof pageMetadataSchema>;
